import { FailureLoading, None, contextPath, handleApiError, post } from "../../ApiCommon";
import { ApiResponse } from "../../ApiResponse";

export type ManagedUserDto = {
    id: string,
    email: string,
    isSuper: boolean,
    isBlocked: boolean,
    lastLoginDate: string,
    lastSearchDate: string,
    isBatchUploadingAvailable: boolean,
    buBrandColumn: number,
    buGoodItemColumn: number,
    buPicUrlsColumn: number,
    isWatermarkingEnabled: boolean
}

export type CreateUserRequestDto = {
    email: string,
    password: string
}

export type ModifyUserRequestDto = {
    email?: string,
    isSuper?: boolean,
    isBlocked?: boolean,
    password?: string,
    isBatchUploadingAvailable: boolean,
    buBrandColumn: number,
    buGoodItemColumn: number,
    buPicUrlsColumn: number,
    isWatermarkingEnabled?: boolean
}

export const UserManagementApi = {
    getAllUsers: (callback: (content: ApiResponse<ManagedUserDto[]>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<None, ApiResponse<ManagedUserDto[]>>(contextPath() + '/api/v1/users/get-all-users', {})
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get all users", onFailure))
    },

    createUser: (createUserRequestDto: CreateUserRequestDto,
                 callback: (content: ApiResponse<ManagedUserDto>) => void,
                 onFailure: (failure: FailureLoading) => void) => {
        post<CreateUserRequestDto, ApiResponse<ManagedUserDto>>(contextPath() + '/api/v1/users/create-user', createUserRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to create user", onFailure))
    },

    modifyUser: (userId: string,
                 modifyUserRequestDto: ModifyUserRequestDto,
                 callback: (content: ApiResponse<ManagedUserDto>) => void,
                 onFailure: (failure: FailureLoading) => void) => {
        post<ModifyUserRequestDto, ApiResponse<ManagedUserDto>>(contextPath() + '/api/v1/users/modify-user/' + userId, modifyUserRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to modify user: " + userId, onFailure))
    },

    clearUser: (userId: string,
                callback: (content: ApiResponse<void>) => void,
                onFailure: (failure: FailureLoading) => void) => {
        post<string, ApiResponse<void>>(contextPath() + '/api/v1/users/clear-user/' + userId, '')
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to clear user: " + userId, onFailure))
    }
}