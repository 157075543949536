import CheckedIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UncheckedIcon from '@mui/icons-material/PanoramaFishEye';
import { Checkbox } from '@mui/material';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { useEffect } from "react";
import Api from "../../../api/Api";
import { ApiResponse } from "../../../api/ApiResponse";
import { ManagedUserDto } from "../../../api/user/management/UserManagementApi";
import { setEditingUser } from '../../../store/actions/data';
import { User } from '../../../store/initialState';
import { useAppDispatch } from '../../../store/store';
import If from "../../utils/If";
import CreateUserForm from "./create/CreateUserForm";
import ModifyUserForm from "./modify/ModifyUserForm";

export default function UserManagementTable() {
    const [users, setUsers] = React.useState<ManagedUserDto[]>();
    const [isUserCreating, setIsUserCreating] = React.useState<boolean>(false);
    const [isUserEditing, setIsUserEditing] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const startUserCreating = React.useCallback(() => {
        setIsUserCreating(true);
    }, []);

    const stopUserCreating = React.useCallback(() => {
        setIsUserCreating(false);
        reloadUsers();
    }, []);

    const startUserEditing = React.useCallback((userDto: ManagedUserDto) => {
        const user: User = {
            id: userDto.id,
            email: userDto.email,
            isSuper: userDto.isSuper,
            abcpSearchUrl: undefined,
            isBatchUploadingAvailable: userDto.isBatchUploadingAvailable,
            buBrandColumn: userDto.buBrandColumn,
            buGoodItemColumn: userDto.buGoodItemColumn,
            buPicUrlsColumn: userDto.buPicUrlsColumn
        };
        dispatch(setEditingUser(user));
        setIsUserEditing(true);
    }, []);

    const stopUserEditing = React.useCallback(() => {
        setIsUserEditing(false);
        reloadUsers();
    }, []);

    const saveUser = React.useCallback((userId: string, editedUser: ManagedUserDto) => {
        Api.userManagement.modifyUser(userId, editedUser, responseDto => {
            reloadUsers();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            });
        });
    }, [snackbarAnchor, enqueueSnackbar]);

    const toggleUserIsSuper = React.useCallback((user: ManagedUserDto) => {
        saveUser(user.id, {
            isSuper: !user.isSuper
        } as ManagedUserDto);
    }, [snackbarAnchor, enqueueSnackbar]);

    const toggleUserIsBlocked = React.useCallback((user: ManagedUserDto) => {
        saveUser(user.id, {
            isBlocked: !user.isBlocked
        } as ManagedUserDto);
    }, [snackbarAnchor, enqueueSnackbar]);

    const toggleIsWatermarkedEnabled = React.useCallback((user: ManagedUserDto) => {
        saveUser(user.id, {
            isWatermarkingEnabled: !user.isWatermarkingEnabled
        } as ManagedUserDto);
    }, [snackbarAnchor, enqueueSnackbar]);

    const reloadUsers = React.useCallback(() => {
        Api.userManagement.getAllUsers((response: ApiResponse<ManagedUserDto[]>) => {
            setUsers(response.body)
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, []);

    const clearUser = React.useCallback((user: ManagedUserDto) => {
        Api.userManagement.clearUser(user.id, responseDto => {
            reloadUsers();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        })
    }, [snackbarAnchor, enqueueSnackbar]);

    useEffect(() => {
        reloadUsers();
    }, []);

    return (
        <div>
            <CreateUserForm open={isUserCreating} onClose={stopUserCreating}/>
            <ModifyUserForm open={isUserEditing} onClose={stopUserEditing}/>
            <Button variant="contained" onClick={startUserCreating}>New user</Button>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>E-Mail</TableCell>
                            <TableCell align="right">is Super</TableCell>
                            <TableCell align="right">is Blocked</TableCell>
                            <TableCell align="right">is Watermarking elabled</TableCell>
                            <TableCell>Last Login date</TableCell>
                            <TableCell>Last Search date</TableCell>
                            <TableCell>CSV is Available</TableCell>
                            <TableCell>CSV Brand Column</TableCell>
                            <TableCell>CSV Good Item Column</TableCell>
                            <TableCell>CSV Pic URLs Column</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user) => (
                            <TableRow
                                key={user.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope='row'>
                                    <IconButton aria-label="delete" onClick={() => clearUser(user)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.id}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => startUserEditing(user)}>
                                        <ModeEditIcon fontSize={"medium"}/>
                                    </IconButton>
                                    {user.email}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => toggleUserIsSuper(user)}>
                                        <If condition={user.isSuper}>
                                            <CheckedIcon fontSize={"medium"}/>
                                        </If>
                                        <If condition={!user.isSuper}>
                                            <UncheckedIcon fontSize={"medium"}/>
                                        </If>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => toggleUserIsBlocked(user)}>
                                        <If condition={user.isBlocked}>
                                            <CheckedIcon fontSize={"medium"}/>
                                        </If>
                                        <If condition={!user.isBlocked}>
                                            <UncheckedIcon fontSize={"medium"}/>
                                        </If>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => toggleIsWatermarkedEnabled(user)}>
                                        <If condition={user.isWatermarkingEnabled}>
                                            <CheckedIcon fontSize={"medium"}/>
                                        </If>
                                        <If condition={!user.isWatermarkingEnabled}>
                                            <UncheckedIcon fontSize={"medium"}/>
                                        </If>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    {user.lastLoginDate}
                                </TableCell>
                                <TableCell>
                                    {user.lastSearchDate}
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        defaultChecked={user.isBatchUploadingAvailable}
                                        checked={user.isBatchUploadingAvailable}
                                        />
                                </TableCell>
                                <TableCell>
                                    {user.buBrandColumn}
                                </TableCell>
                                <TableCell>
                                    {user.buGoodItemColumn}
                                </TableCell>
                                <TableCell>
                                    {user.buPicUrlsColumn}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}