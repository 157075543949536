export interface User {
    id?: string,
    email?: string,
    isSuper?: boolean,
    abcpSearchUrl?: string,
    isBatchUploadingAvailable?: boolean,
    buBrandColumn?: number,
    buGoodItemColumn?: number,
    buPicUrlsColumn?: number,
    isWatermarkingEnabled?: boolean
}

export interface StoreContent {
    user: User,
    editingUser: User
}

const initialState: StoreContent = {
    user: {} as User,
    editingUser: {} as User
}

export default initialState